import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-convert-confirmation-dialog',
  templateUrl: './convert-confirmation-dialog.component.html',
  styleUrls: ['./convert-confirmation-dialog.component.scss']
})
export class ConvertConfirmationDialogComponent implements OnInit {
  ids: number [] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    translateService: TranslateService,
    private dialogRef: MatDialogRef<any>) {
  }

  ngOnInit(): void {
    this.ids = this.data.ids;
  }

  confirm(event): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    close();
  }
}
