import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-delete-confirmation-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.scss']
})
export class DeleteConfirmationDialogComponent implements OnInit {
  ids: number [] = [];
  public title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    translateService: TranslateService,
    private dialogRef: MatDialogRef<any>) {
  }

  ngOnInit(): void {
    this.ids = this.data.ids;
    if (this.data.title) {
      this.title = this.data.title;
    }
  }

  confirm(event): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    close();
  }
}
