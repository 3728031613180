<p>
<!--  {{title ? title : ('core.components.deleteConfirmationDialog.text' | translate)}}-->
  <ng-container *ngIf="title; else noTitle" >
    {{title}}
  </ng-container>
  <ng-template #noTitle>
    <span translate>core.components.deleteConfirmationDialog.text</span>
  </ng-template>
</p>
<div class="modal-actions">
  <button

    mat-flat-button
    color="warn"
    (click)='confirm($event)'>

    <mat-icon  svgIcon="delete" class="white-icon"></mat-icon>
    <span translate >core.components.deleteConfirmationDialog.delete</span>
  </button>
  <div class="divider"></div>
  <button
    mat-button
    [mat-dialog-close]='cancel()'>
    <span translate>core.components.deleteConfirmationDialog.cancel</span>
  </button>


</div>
