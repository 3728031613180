import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-export-confirmation-dialog',
  templateUrl: './export-confirmation-dialog.component.html',
  styleUrls: ['./export-confirmation-dialog.component.scss']
})
export class ExportConfirmationDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    translateService: TranslateService,
    private dialogRef: MatDialogRef<any>) { }

  ngOnInit(): void {
  }
  confirm(event): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    close();
  }
}
