<p   translate>core.components.exportConfirmationDialog.text

</p>
<div class="modal-actions">
  <button

    mat-flat-button
    color="warn"
    (click)='confirm($event)'>

    <span translate >core.components.exportConfirmationDialog.delete</span>
  </button>
  <div class="divider"></div>
  <button
    mat-button
    [mat-dialog-close]='cancel()'>
    <span translate>core.components.exportConfirmationDialog.cancel</span>
  </button>


</div>
