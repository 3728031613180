<p   translate>core.components.convertConfirmationDialog.text

</p>
<div class="modal-actions">
  <button

    mat-flat-button
    color="warn"
    (click)='confirm($event)'>

    <mat-icon  svgIcon="delete" class="white-icon"></mat-icon>
    <span translate >core.components.convertConfirmationDialog.delete</span>
  </button>
  <div class="divider"></div>
  <button
    mat-button
    [mat-dialog-close]='cancel()'>
    <span translate>core.components.convertConfirmationDialog.cancel</span>
  </button>


</div>
